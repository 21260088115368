.XCRegister {
    width: 100%;
    height: 100%;
    display: flex;
    h1 {
        position: absolute;
        top: 12%;
        left: 15%;
        font-size: 2rem;
        color: #fff;
        z-index: 1;
        font-family: Caveat;
    }
    .XCBackground {
        background: rgb(209,40,40);
        background: radial-gradient(circle, rgba(209,40,40,1) 28%, rgba(114,15,15,1) 63%, rgba(22,40,39,1) 100%);
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: fixed;
    }

    .XCTreesBg {
        width: 100%;
        position: absolute;
        z-index: 0;
        top: 30%;
    }

    .XCFlyingSanta {
        width: 25%;
        position: absolute;
        right: 10%;
        opacity: 0.3;
        top: 6%;
    }

    .XCLogoContainer {
        width: 80px;
        height: 80px;
        border-radius: 70px;
        background-color: #fff;
        padding: 10px;
        position: absolute;
        top: 5%;
        left: 2%;
    }

    .XCLogo {
        width: 100%;
    }

    .XCForeground {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .XCHeader {
        position: relative;
        min-height: 25%;
    }

    .XCBook {
        position: relative;
        width: 100%;
    }

    .XCBookBackground {
        position: relative;
        z-index: 1;
        overflow-x: hidden;
        width: 100%;
    }

    .XCBookSvg {
        width: 100%;
        position: relative;
        top: 20%;
        left: 10%;
    }
}

.XcGuideWindowContainer {
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    left: 0;
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 2;
    padding: 1.5rem;
    box-sizing: border-box;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
}

.XcGuideWindow {
    width: 100%;
    height: 100%;
    max-width: 850px; 
    max-height: 700px;
    background: #fff;
    padding: 1.5rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    border-radius: 10px;
    -webkit-box-shadow: 4px 4px 10px -4px rgba(0,0,0,0.61);
    -moz-box-shadow: 4px 4px 10px -4px rgba(0,0,0,0.61);
    box-shadow: 4px 4px 10px -4px rgba(0,0,0,0.61);
    h1 {
        color: #000 !important;
        font-family: Quicksand !important;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 768px) {
        max-width: auto;
        max-height: auto;
        border-radius: 0;
    }
}

.XcGuideWindowContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: scroll;
    font-size: 1.2rem;
    line-height: 1.5rem;
    ul {
        list-style: disc;
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        gap: .5rem;
        margin-bottom: 20px;
    }
}

.XcGuideWindowControls {
    flex: 0;
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
        border: 0;
        background: #000;
        color: #fff;
        border-radius: 5px;
        padding: .5rem;
        cursor: pointer;
        opacity: .9;
        font-size: 1.02rem;
        font-weight: 600;
        &:hover {
            opacity: 1;
        }
    }
}
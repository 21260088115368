.XcChallengeResources {
    width: 100%;
    max-width: 990px;
    background: #fff;
    border-radius: 10px;
    margin: auto;
    position: relative;
    z-index: 1;
    border-width: 4px;
    border-style: solid;
    border-color: #4D1B1A;
}

.XcChallengeResources__list {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 15px;
    justify-content: center;
    align-items: center;
}

.XcChallengeResourceItem {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-width: 4px;
    border-style: solid;
    border-color: #4F166D;
    border-radius: 10px;
    overflow: hidden;
    min-height: 156.5px;
    width: 208px;
    box-sizing: border-box;
    i {
        height: 80px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 30%;
            opacity: 0.8;
        }
    }
}

.XcChallengeResourceItem__image {
    width: 200px;
    height: 112.5px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; 
}

.XcChallengeResourceItem__label {
    padding: 6px;
}

@import "../../colors.scss";


.share-home {

    header {
       // align-items: start;
    }

   .space-placeholder {
    //background-color: red;
    //min-width: 100px;
    height: 100px;
    flex: 1;
   }

    .open-app {
        //margin-top: 24px;
        color: #252D5C;
       

        .open-app-btn {
            background-color: #252D5C;
            padding: 0.6rem 1rem;
            border-radius: 8px;
            border: none;
            color: $white;
            //cursor: pointer;
            text-wrap: nowrap;

            font-size: 0.85rem;
            font-weight: 800;
        }

    }

    .contact-placeholder {
        margin-top: 32px;
    }
}

.share-container {

    //margin-top: 36px;

    .share-inner-container-row {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-evenly;

        .content-container {
            max-width: 580px;
            padding-right: 16px;
            padding-left: 16px;
        }

        .playmoment-image-container {

            min-width: 460px;
            width: 30%;
            //background-color: red;

          

            .image-container {
                //height: 100vh;
                //background-color: gray;
                position: relative;

                .background-color {
                    position: absolute;
                    width: 100%;
                    height: 90%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                .playmoment-image {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10,
                }

                .placeholder {
                    z-index: -1;
                    width: 100%;
                }

                .character-image {
                    height: 8rem;
                    position: absolute;
                    top: 140px;
                    right: 24px;
                    z-index: 11;
                }
            }
        }


    }

    .share-inner-container-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        //background-color: gray;

        .content-container {
            padding-right: 16px;
            padding-left: 16px;
            max-width: 580px;
            //
        }

        .playmoment-image-container {

            min-width: 360px;

            .image-container {
                position: relative;

                .background-color {
                    position: absolute;
                    width: 100%;
                    height: 90%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                }

                .playmoment-image {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 10,
                }

                .placeholder {
                    z-index: -1;
                    width: 100%;
                }

                //height: 100%;
                //background-color: gray;
                .character-image {
                    height: 8rem;
                    position: absolute;
                    bottom: 12px;
                    right: 24px;
                    z-index: 11;
                }
            }
        }


    }

    .playmoment-image-container {

        margin-bottom: 24px;

        .image-container {
            position: relative;
        }

    }

    .title-column {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        //flex-wrap: wrap;

        h1 {
            font-size: 1.8rem;
            color: #252D5C;
            //padding-bottom: 1.5rem;
            margin-right: 6px;
        }

        .likes-container {
            display: flex;
            flex-direction: row;
            background-color: #252D5C;
            align-items: center;
            max-height: 28px;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-right: 20px;
            padding-left: 14px;
            border-radius: 10px;

            .like-icon {
                height: 24px;
            }

            .likes-count {
                color: white;
                margin-left: 16px;
                font-size: 1.2rem;
            }
        }
    }

    .stats-container {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;

        .location-item {

            display: flex;
            flex-direction: row;
            align-items: center;

            .location-icon {
                height: 40px;

            }

            .location-title {
                font-size: 1.125rem;
                margin-left: 16px;
                font-weight: 800;
            }
        }

        .clock-item {

            display: flex;
            flex-direction: row;
            align-items: center;

            .clock-icon {
                height: 40px;
            }

            .clock-title {
                font-size: 1.125rem;
                margin-left: 16px;
                font-weight: 800;
            }

        }

        .spark-item {

            display: flex;
            flex-direction: row;
            align-items: center;

            .spark-icon {
                height: 40px;
            }

            .spark-title {
                font-size: 1.125rem;
                margin-left: 16px;
                font-weight: 800;
            }

        }

    }

    .value-tags {
        display: flex;
        flex-direction: row;
        column-gap: 16px;
        row-gap: 16px;
        margin-top: 16px;
        align-content: flex-start;
        flex-wrap: wrap;

        .value-item {
            padding: 8px;
            padding-left: 16px;
            padding-right: 16px;
            border-radius: 8px;
            color: white;

            font-size: 1.1rem;

            font-weight: 600;
        }

    }

    .description-container {

        margin-top: 32px;
        display: flex;
        justify-content: flex-start;

        .description-text {
            line-height: 1.5rem;
            color: #252D5C;
            font-size: 1.125rem;
            //text-align: left;
        }

    }



    .download-title-container {
        margin-top: 32px;
        padding-bottom: 32px;

        .title {
            color: #252D5C;
            font-size: 1.7em;
        }

        // h1: {
        //     font-size: 1em;

        // }
        //padding-bottom: 1.5rem;
    }

    .download-actions {
        display: flex;
        flex-direction: row;
        //column-gap: 48px;
        padding-bottom: 16px;

        .download-image {
            height: 60px;
            padding-right: 38px;
        }

        .download-image-small {
            height: 48px;
            padding-right: 32px;
        }
    }

    .points-container {
        margin-top: 24px;
        padding-bottom: 56px;
        display: flex;
        //justify-content: flex-start;

        p {
            text-align: left;
            // background-color: green;
        }

        .point-item {
            //justify-content: flex-end;
            display: flex;
            flex-direction: row;
            padding-top: 14px;

            .check-icon {
                margin-right: 16px;
            }

            .item-title {
                color: #252D5C;
                font-size: 1.2rem;
                font-weight: 800;
            }

            //background-color: red;
            //justify-content: flex-start;
            //align-items: flex-start;
            //align-content: flex-start;
            // /text-align: left;
        }
    }

}
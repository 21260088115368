.XcChallengeResourceWindow {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    background: #fff;
    top: 0;
    left: 0;
    overflow-y: scroll;
}

.XcChallengeResourceWindowHeader {
    display: flex;
    flex-direction: row;
    gap: 5px;
    height: 80px;
    align-items: center;
    padding: 0 15px 0 15px;
    // border: 1px solid #c0c0c0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
}

.XcChallengeResourceWindowHeader__closeButton {
    flex: 0 0;
    border: 0;
    background: transparent;
    cursor: pointer;
}

.XcChallengeResourceWindowHeader__title {
    flex: 1;
    text-align: right;
    span {
        padding: 5px;
        background: #fff;
        font-weight: bold;
    }
}


.XcChallengeResourceFrame {
    width: 100%;
    height: 100%;
    position: absolute;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        padding: 0 0 100px 0;
        margin: auto;
    }
}

.XcChallengeResourceControls {
    position: absolute;
    z-index: 1;
    top: 40vh;
    left: 0;
    width: 100%;
}

.XcChallengeResourceControls__prevButton, .XcChallengeResourceControls__nextButton {
    border: 0;
    background: transparent;
    cursor: pointer;
    img {
        width: 25px;
        opacity: 0.3;
        &:hover {
            opacity: 0.8;
        }
    }
}

.XcChallengeResourceControls__prevButton {
    position: absolute;
    left: 15px;
    top: 0;
}

.XcChallengeResourceControls__nextButton {
    position: absolute;
    right: 15px;
    top: 0;
}
@import '../../colors.scss';

.delete-account-article {
    padding-left: 5rem;

    h1 {

        color: $black;
        text-align: left;
    }


    .article-container {
        //display: 'flex',

        .overview {
            margin-bottom: 1.5rem;
        }

        .subtitle {
            color: $black;
            max-width: 800px;
            text-align: left;
        }

        .delete-list li {
            margin: .25rem 0 .25rem 1.25rem;
            display: list-item;
            list-style-type: disc;
            max-width: 800px;
            margin-top: 1rem;
        }

        .TextSide {
            flex: 2 1;

            p {
                line-height: 1.4em;
                padding-bottom: 1rem;
                max-width: 800px;
                margin: 0;
                text-align: left;
            }

            h1 {
                max-width: 800px;
            }

            h2 {
                max-width: 800px;
            }
        }
    }
}
.LangSelectButton {
    display: flex;
    flex: 0 0 50px;
    gap: 5px;
    height: 22px;
    cursor: pointer;
    z-index: 1000;
    color: #424242;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: .5rem;
    border-radius: 5px;
    &:hover {
        background-color: #e0e0e0;
    }
    span {
        width: auto;
    }
    img {
        border-radius: 5px;
        user-select: none;
    }
}

.LangSelectButton--open {
    background-color: #e0e0e0;
}

.LangSelectMenu {
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid #f0f0f0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #424242;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border-radius: 5px;
    li {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 1px;
        padding-bottom: 1px;
        cursor: pointer;
        display: flex;
        gap: 5px;
        span {
            width: 80px;
        }
        img {
            border-radius: 5px;
        }
        &:hover {
            background-color: #e0e0e0;
        }
    }
}
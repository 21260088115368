@import "../../colors.scss";

.AboutUs {
    .Content {
        padding-left: 2rem;
        padding-right: 2rem;
        @media only screen and (max-width: 1024px) {
            padding-left: 0;
            padding-right: 0;
        }
        .ImageSide {
            width: 500px;
            text-align: center;
            @media only screen and (max-width: 1024px) {
                display: none;
                width: auto;
                text-align: auto;
            }
        }
    }

    .AboutUsTop {
        .Content {
            padding-top: 0;
        }
    }

    h3 {
        margin-bottom: .5rem;
    }
}

.AboutUsImpact {
    padding-bottom: 2.5rem;
}

.AboutUsTeamSection {
    h1, h2 {
        text-align: center;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.AboutUsTeam__img {
    display: inline-block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100px;
    border-width: 7px;
    border-style: solid;
    border-color: $pink;
}

.AboutUsTeam {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 2.5rem;
    @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        li {
            margin-bottom: 2rem;
            gap: 0 !important;
            img {
                margin-bottom: 0.8rem;
            }
        }
    }
    li {
        display: flex;
        flex-direction: column;
        width: 200px;
        gap: .8rem;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    li:first-child {
        img {
            border-color: $red;
        }
    }
    li:last-child {
        img {
            border-color: $yellow;
        }
    }
}

.AboutUsTeam__title {
    line-height: 1.5rem;
}

.AboutUsTeam__name {
    font-weight: 700;
}
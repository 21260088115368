body {
    box-sizing: border-box;
}

.XCFlyingSanta {
    width: 25%;
    min-width: 200px;
    position: absolute;
    right: 10%;
    opacity: 0.3;
    top: 20px;
}

.XcChallenges {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    padding-bottom: 300px;

    .XcHashTag {
        background: #fff;
        color: #9044D5;
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        width: 320px;
        justify-content: center;
        padding: .5rem 1rem;
        border-radius: 20px;
        gap: .5rem;
        cursor: pointer;
        b {
            font-size: .8rem;
            background: #c0c0c0;
            color: #404040;
            padding: 5px;
            border-radius: 3px;
            width: 45px;
            text-align: center;
        }
        i {
            font-size: 2rem;
            font-weight: bold;
            font-family: Arial;
        }
    }

    .XcHashTagContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: absolute;
        margin-top: 150px;
        .XcHashTag {
            display: none;
        }
        @media screen and (max-width: 768px) {
            .XcHashTag {
                display: flex;
            }
        }
    }

    .XCTreesBg {
        width: 100%;
        position: absolute;
        z-index: 0;
        top: 30%;
    }

    .XCForeground {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .XCHeader {
        position: relative;
        z-index: 1;
        height: 80px;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        padding: 1.5rem;
        .XcHashTag {
            margin-left: 20px;
        }
        @media screen and (max-width: 768px) {
            .XcHashTag {
                display: none;
            }
        }
    }

    .XCHeaderContent {
        flex: 1 1;
        display: flex;
        align-items: center;
    }

    .XCLogoContainer {
        flex: 0 0;
    }

    .XCLogo {
        width: 80px;
    }

    h1 {
        position: relative;
        font-size: 1.5rem;
        color: #fff;
        z-index: 1;
        font-family: Caveat;
    }

    .XcChallengeListContainer {
        padding: 0 1rem;
    }

    .XcChallengeList {
        background-color: #609465;
        position: relative;
        margin: 0 auto;
        border-radius: 3px;
        max-width: 900px;
        width: 100%;
        display: flex;
        gap: 1rem;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 2rem 1rem;
        box-sizing: border-box;
    }

    @keyframes glow {
        from {
            box-shadow: 0 0 0 0 #fff;
        }
      
        to {
            box-shadow: 0 0 50px 15px #fff;
        }
    }

    .XcChallengeCard {
        position: relative;
        overflow: hidden;
        width: 100px;
        height: 145px;
        background-color: #896797;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        &--even {
            background-color: #fff;
        }
        &--glow {
            animation-duration: 1s;
            animation-name: glow;
            animation-iteration-count: infinite;
            animation-direction: alternate;
        }
    }

    .XcChallengeCard__image {
        position: absolute;
    }

    .XcChallengeCard__peel {
        position: absolute;
    }

    .XcChallengeCard__label {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        background: rgba(0,0,0,0.5);
        color: #fff;
        font-size: 0.9rem;
    }

    .XcFooter {
        width: 100%;
        flex: 0 0 4rem;
    }
}
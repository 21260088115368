.How {
    min-height: 650px !important;
    position: relative;
    width: 100%;
    overflow: hidden;
    color: #424242;
    position: relative;
    padding-top: 4rem;
    display: table !important;
    border-spacing: 1rem;
}

.How__contentCell {
    display: table-cell;
}

.How__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow: hidden;
    h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
        color: #252D5C;
    }
    h3 {
        padding-bottom: 1rem;
        font-size: 23px;
        font-weight: 400;
        line-height: 2rem;
    }
    h4 {
        padding-bottom: .5rem;
        font-size: 23px;
    }
    p {
        line-height: 1.4rem;
        font-size: 1.125rem;
    }
    i {
        display: block;
        font-size: 6rem;
        color: #fff;
        width: 200px;
        height: 200px;
        font-style: normal;
        background-color: #FFDE59;
        border-radius: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 800;
    }
    ul {
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }
    li {
        display: flex;
        gap: 1rem;
        div {
            flex: 1 0;
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 500px;
        }
        &:nth-child(2) {
            i {
                background-color: #FF66C4;
            }
        }
        &:nth-child(3) {
            i {
                background-color: #1DD4B7;
            }
        }
        @media only screen and (max-width: 1024px) {
            flex-direction: column;
            align-items: center;
        }
    }
    padding-bottom: 3rem;
    @media only screen and (max-width: 1024px) {
        padding-right: 0;
        padding-bottom: 0;
    }
}

.How__image {
    display: table-cell;
    width: 600px;
    position: relative;
    justify-content: center;
    align-items: center;
    img {
        position: absolute;
        width: 100%;
    }
    @media only screen and (max-width: 1370px) {
      display: none;
    }
  
    
}
.XcCountdown {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 15px 15px 0 15px;
    @media screen and (max-width: 768px) {
    }
}

.XcCountdownContent {
    width: 100%;
    max-width: 900px;
    display: flex;
    gap: 1rem;
    flex-direction: row;
    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-top: 100px;
    }
    // @media screen and (max-width: 768px) {
    //     flex-direction: column-reverse;
    // }
}

.XcCountdownGraphic {
    min-width: 170px;
    position: relative;
    // padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    @media screen and (max-width: 768px) {
        padding-left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: -50px;
    }
}

.XcCountdown__PolarContainer {
    position: relative;
    z-index: 1;
    height: 170px;
    padding-left: 150px;
    @media screen and (max-width: 768px) {
        position: relative;
        margin: 0 0 0 0 !important;
        padding-left: 0;
    }
}

.XcCountdown__Polar {
    height: 100%;
}

.XcCountdown__StarPortalContainer {
    position: relative;
    z-index: 1;
    height: 300px;
    padding-left: 10px;
    @media screen and (max-width: 768px) {
        position: relative;
        margin: 0 0 0 0 !important;
        padding-left: 0;
    }
}

.XcCountdown__StarPortal {
    height: 100%;
}

[aria-label=animation] {
    position: absolute;
    bottom: 0;
    @media screen and (max-width: 768px) {
        position: relative;
        margin: 0 -130px 0 0 !important;
    }
}

.XcCountdownMessage {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 140px;
    padding-right: 10px;
    opacity: 0.9;
    box-sizing: border-box;
    h1 {
        font-weight: 500;
        font-size: 1.7rem !important;
        button {
            border: 0;
            background: #fff;
            color: #431864;
            padding: .5rem;
            font-size: 1rem;
            border-radius: 5px;
            font-weight: 600;
            cursor: pointer;
            margin-top: 20px;
            margin-bottom: 20px;
            opacity: 1;
            &:hover {
                opacity: .9;
            }
        }
    }
    @media screen and (max-width: 768px) {
        width: 400px;
        align-items: center;
        padding: 0 10px;
        text-align: center;
        width: 100%;
    }
    @media screen and (max-width: 900px) {
        h1 {
            font-size: 1.5rem !important;
        }
    }
}

.XcCountdownTimer {
    display: flex;
    gap: .8rem;
    flex-wrap: wrap;
    font-weight: bold;
    font-size: .7rem;
}

.XcCountdownTimerUnit {
    display: flex;
    gap: .6rem;
    flex-direction: column;
    min-width: 50px;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
        margin-bottom: 0;
    }
}

.XcCountdownTimerUnit__label {
    font-size: 1.2rem;
    font-weight: 400;
    @media screen and (max-width: 900px) {
        font-size: 0.8rem;
        font-weight: 600;
    }
}

.XcCountdownTimerUnit__value {
    display: flex;
    gap: .2rem;
    flex-direction: row;
    span {
        display: flex;
        width: 45px;
        height: 45px;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        border-radius: 2px;
        background: #fff;
        color: #707070;
        font-weight: 500;
        @media screen and (max-width: 900px) {
            font-size: 1.2rem;
            width: 30px;
            height: 30px;
            font-weight: 600;
        }
    }
}

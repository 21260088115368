.XcChallengeSteps {
    max-width: 990px;
    height: auto;
    background: #261C2B;
    flex: 1;
    min-height: 500px;
    border-radius: 10px;
    border-width: 4px;
    border-style: solid;
    border-color: #4F166D;
}

.XcChallengeSteps__list {
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.XcChallengeStep {
    border-radius: 5px;
    background: #fff;
    padding: 15px 20px 15px 20px;
    box-sizing: border-box;
    min-height: 50px;
    position: relative;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 8px;
}

.XcChallengeStep__bullet {
    position: absolute;
    top: -15px;
    left: -15px;
}
.XcChallengeFooter {
    height: 320px;
    background: url('./images/footer.png');
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.XcChallengeFooter__Polar{
    position: absolute;
    bottom: -20px;
    right: -20px;
    transform: rotate(-35deg);
    display: none;
}

.XcChallengeFooterSocials {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    img {
        width: 40px;
        cursor: pointer;
    }
    background: rgba(255,255,255,0.5);
    padding: .5rem 1rem;
    border-radius: 20px;
}
@import "../../colors.scss";

.DownloadRedirect {
    .DownloadControls {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: .5rem;
        button {
            border: none;
            cursor: default;
            padding: 0;
            margin: 0;
            display: flex;
            background: none;
            opacity: 0.3;
        }
        a {
            opacity: 0.9;
            &:hover {
                opacity: 1;
            }
        }
    }
    .TextSide {
        h1 {
            text-align: left;
            i {
                white-space: nowrap;
                font-style: normal;
            }
        }
    }
}
.XcChallengeDetails {
    width: 100%;
    position: relative;
    overflow: hidden;
    color: #5F5F5F;
    line-height: 1.5rem;
    padding-bottom: 320px;
}

.XcChallengeDetailsBg {
    background: #C30F16;
    // background: linear-gradient(90deg, rgba(25,47,45,1) 0%, rgba(107,17,17,1) 16%, rgba(209,40,40,1) 35%, rgba(209,40,40,1) 62%, rgba(114,15,15,1) 80%, rgba(33,52,51,1) 100%);
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
}

.XcChallengeDetailsHeader {
    max-width: 990px;
    margin: auto;
    padding: 2rem;
}

.XcBackButton {
    color: #fff;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

// .XcBackButton__icon {
//     color: #fff;
// }

.XcBackButton {
    display: flex;
    gap: .5rem;
    background: transparent;
    border: 0;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
}

.XcNextSectionButton {
    display: flex;
    gap: .5rem;
    background: transparent;
    border: 0;
    font-size: 1.2rem;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    align-items: center;
    justify-content: center;
    height: 50px;
}

.XcChallengeContent {
    display: flex;
    gap: 1rem;
    width: 100%;
    max-width: 990px;
    margin: auto;
    flex-direction: row;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.XcChallengeSummaryContainer, .XcChallengeStepsContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.XcChallengeSummaryContainer {
    @media screen and (max-width: 768px) {
        .XcNextSectionButton {
            display: none;
        }
    }
}

.XcNextSection__icon {
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
}



.XcChallengeResourcesContainer {
    width: 100%;
    max-width: 990px;
    min-height: 200px;
    margin: auto;
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}
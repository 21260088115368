.XcAppPromoContainer {
    width: 100%;
    max-width: 900px;
    box-sizing: border-box;
    display: flex;
    margin: 1rem auto;
    @media screen and (max-width: 940px) {
        padding: 1rem;
        margin: auto;
    }
}

.XcAppPromo {
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    gap: .5rem;
    padding: 2rem 1rem;
    box-sizing: border-box;
    &__text {
        font-size: 18px;
        line-height: 1.4rem;
        display: flex;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        gap: 1rem;
        opacity: 0.75;
        p {
            text-align: left;
            max-width: 300px;
        }
    }
    img {
        width:421px;
        height:300px;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        p {
            text-align: center;
            width: 100%;
            max-width: 100%;
        }
        img {
            width:280px;
            height:200px;
        }
    }
}
@import "../../colors.scss";

.Overlay {
    background: #9531e256;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Dialog {
    background-color: white;
    overflow: hidden;
    width: 100%;
    max-width: 512px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5rem;
    justify-content: center;
    margin: auto 1rem;
    border-radius: .5rem;
    h3 {
        font-weight: 600;
        font-size: 1.25rem;
    }

    .Dialog__actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        button {
            border: 0;
            margin-left: .3rem;
            border-radius: .3rem;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, .15);
            padding: 0.625rem 1.25rem;
            font-weight: 600;
            background-color: lighten($black, 55%);
            cursor: pointer;
            &:hover {
                background-color: lighten($black, 50%)
            }
            &:focus {
                box-shadow: 0px 0px 0px 3px fadeout(lighten($black, 20%), 70%);
            }
            color: $black;
            &[type=submit] {
                background-color: $green;
                color: #fff;
                &:hover {
                    background-color: darken($green, 10%);
                }
                &:focus {
                    box-shadow: 0px 0px 0px 3px fadeout(lighten($green, 20%), 70%);
                }
            }
        }
    }

    @media screen and (min-width: 840px) {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .25);
        width: calc(100% / 3 * 2);
        margin: auto;
    }
}
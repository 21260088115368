@import 'cssreset.scss';
@import 'colors.scss';

a {
  text-decoration: none;
  cursor: pointer;
}

.App-logo {
  padding: 1rem 2rem;
  height: 120px;
  @media only screen and (max-width: 1024px) {
    padding: .5rem;
    height: 100px;
  }
}

header {
  display: flex;
  align-items: center;
  .Actions {
    flex: 0 0  320px;
    button {
      width: 140px;
      padding: 0.7rem 1rem;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 0.8rem;
      font-weight: 600;
      background-color: $green;
      color: $white;
      cursor: pointer;
      &:hover {
        background-color: darken($green, 10%);
      }
    }
  }
  button.demoButton {
    background-color: $yellow;
    color: $black;
    &:hover {
      background-color: darken($yellow, 10%);
    }
  }
}

.GradientSection {
  background-image: radial-gradient(farthest-corner at 80vw 100vw,
      lighten($onepurple, 1%) 0%, $onepurple 30%);
  min-height: 200px;
  padding-left: 5rem;
  padding-right: 5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: $white;
  .Content {
      color: #fff;
      h1 {
        color: #fff;
      }
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.WhiteSection {
  min-height: 200px;
  background-color: $white;
  padding-left: 5rem;
  padding-right: 5rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media only screen and (max-width: 1024px) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.noPadding {
  padding: 0;
}

#method {
  .ContentList {
    img {
      height: 200px;
      object-fit: cover;
      object-position: center;
    }
  }
}

.Content {
  padding: 3rem 0 1rem 0;
  max-width: 1440px;
  width: 100%;
  text-align: left;
  color: $black;
  h1 {
    color: $secondary;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 2rem;
    text-align: center;
    &.hasSubHeading {
      padding-bottom: 2rem;
    }
  }
  h2 {
    text-align: center;
    padding-bottom: 1rem;
    font-size: 23px;
    font-weight: 400;
    line-height: 2rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    max-width: 600px;
    margin: auto;
    text-align: center;
  }
  ul.ContentList {
    list-style: none;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    li {
      flex: 1 1;
      position: relative;
      min-width: 200px;
      text-align: center;
    }
    i {
      width: 100%;
      text-align: center;
      display: block;
      padding: 10px;
      box-sizing: border-box;
    }
    h3, p {
      text-align: center;
    }
    h3 {
      font-size: 23px;
      font-weight: 400;
      padding: 0 1rem 1rem 1rem;
      margin-top: 0;
    }
    p {
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding-bottom: 1rem;
    }
    img {
      max-width: 300px;
      width: 100%;
      border-radius: 50px;
    }
  }
  .SideImage {
    display: flex;
    flex-direction: row;
    .ImageSide {
      position: relative;
      flex: 1 0 0; 

      svg {
        max-width: 400px;
      }
    }
    .TextSide {
      flex: 2 1;
      p {
        line-height: 1.4em;
        padding-bottom: 1rem;
        max-width: 800px;
        margin: 0;
        text-align: left;
      }
      h1 {
        max-width: 800px;
      }
      h2 {
        max-width: 800px;
      }
    }
  }
  ul.ListOfPoints {
    padding-top: 0;
    padding-bottom: 1rem;
    list-style: disc;
    margin-left: 1.5rem;
    li {
      font-weight: 600;
      font-size: 1.2rem;
      padding: .5rem;
    }
  }
}

.OurMission {
  h1, h2 {
    text-align: left;
  }
  h1 {
    font-size: 2em;
    padding-bottom: 1.5rem;
  }
}

.Method {
  h2 {
    max-width: 600px;
    margin: auto;
  }
}

.HomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Home header {
  display: flex;
  width: 100%;
  max-width: 1440px;
}

.BannerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('./images/banner.png');
  padding: 2rem 5rem;
  @media only screen and (max-width: 1024px) {
    padding: 2rem 2rem;
  }
}

.Banner {
  box-sizing: border-box;
  display: flex;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0 0 6rem 0;
  color: $black;
  
  .Banner__slogan {
    justify-content: center;
    align-items: right;
    flex: 0 0 600px;
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0,.7);
    margin-top: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
    @media only screen and (max-width: 1024px) {
      padding: 1rem;
    }
    h1, b, i {
      margin: 0rem 2rem;
      font-style: normal;
    }
    h1 {
      color: #fff ;
      font-weight: 800;
      font-size: 3rem;
      line-height: 2.6rem;
      padding-bottom: 5px;
    }
    i {
      padding-bottom: 1rem;
      font-size: 1.2rem;
      opacity: 0.7;
    }
    b {
      text-align: left;
      font-size: 23px;
      font-weight: 400;
      line-height: 2rem;
      color: #fff;
    }
    .HashTag {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @media only screen and (max-width: 1024px) {
        justify-content: flex-start;
      }
      em {
        height: 40px;
        background-color: $onepurple;
        border-radius: 50px;
        color: #fff;
        padding: 0 15px;
        font-style: normal;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .HashTag__hash {
        width: 50px;
        height: 50px;
        font-size: 700;
      }

      .HashTag__label {
        margin-left: -20px;
        border-radius: 0 50px 50px 0;
      }
    }
    @media only screen and (max-width: 1024px) {
      flex: 0 0;
      display: flex;
      h1, b, i {
        margin: 0;
        text-align: left;
      }
      h1 {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
  .Banner__image {
    flex: 1 0;
    text-align: center;
    img {
      width: 100%;
    }
  }
}

.Banner__footer {
  width: 100%;
  max-width: 1440px;
  padding-bottom: 2rem;
  color: $black;
  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1 0;
      list-style: none;
      b {
        font-weight: 500;
        font-size: 1.125rem;
        margin-left: 20px;
      }
      img {
        margin-top: -20px;
      }
    }
  }
}

.Registration {
  .TopButton {
    text-transform: uppercase;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -25px;
    width: 50px;
    box-sizing: border-box;
    text-align: center;
    padding: 0.5rem 0;
    background-color: $teal;
    color: $navy;
    cursor: pointer;
    &:hover {
      background-color: darken($teal, 10%);
    }
    border: 0;
    border-radius: 5px 5px 0 0;
    font-weight: 600;
  }

  form {
    padding: 3rem 0 4rem 2rem;
    max-width: 1440px;
    width: 100%;
    text-align: left;
    color: #fff;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 300;
      padding: 1rem 0;
      margin: 0;
      font-size: 2rem;
    }
    em {
      padding: 0;
      transition: 1s all;
      height: 0;
      line-height: 0;
      overflow: hidden;
      opacity: 0;
      max-width: 400px;
      font-style: normal;
      &.shown {
        padding: 1rem 0;
        height: auto;
        line-height: normal;
        opacity: 1;
      }
      a {
        color: $white;
        &:hover {
          color: darken($white, 20%);
        }
      }
    }
    input {
      padding: 1.1rem;
      width: 400px;
      max-width: 80vw;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 1.2rem;
      background-color: lighten($onepurple, 70%);
      color: lighten($onepurple, 20%);
      border: 2px solid $onepurple;
      outline: transparent;
      &:focus {
        background-color: $white;
        border: 2px solid $sky;
      }
    }
    button {
      width: 200px;
      padding: 1.1rem;
      margin: 0.5rem 0.5rem 0.5rem 0;
      border-radius: 5px;
      border: 0;
      font-size: 1.2rem;
      background-color: $sky;
      color: $white;
      cursor: pointer;
      &:hover {
        background-color: darken($sky, 10%);
      }
      &.disabled {
        cursor: not-allowed;
        background-color: #b3b3b3c7;
        color: $black;
        &:hover {
          background-color: #b3b3b3c7;
        }
      }
    }
    i {
      margin-top: 1rem;
      width: 100%;
      text-align: right;
      font-style: normal;
      b {
        font-weight: 600;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  header {
    flex-direction: row;
    .Actions {
      text-align: center;
      flex: 0;
      padding: 1rem 0;
    }
    .MainMenu {
      ul {
        padding: 1rem 0 1rem 0;
      }
    }
  }
  .Banner {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem 0;
    .Banner__image {
      max-width: 700px;
      padding-bottom: 1rem;
    }
  }

  .Banner__footer {
    max-width: 700px;
    margin-top: 2rem;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      li {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: flex-start;
        margin-bottom: 1rem;
        min-width: 320px;
      }
    }
  }

  .Content {
    padding: 2rem 0;
    ul.ContentList {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      li {
        max-width: 500px;
      }
    }
  }

  .Registration {
    form {
      width: 100%;
      max-width: 1440px;
      padding: 2rem 1rem 4rem 0;
    }
  }

  .Content {
    .SideImage {
      display: flex;
      flex-direction: column-reverse;
      .ImageSide {
        flex: 0 0 200px; 
        margin: 2rem 0;
      }
      .TextSide {
        flex: 2 1;
        p {
          line-height: 1.4em;
        }
      }
    }
  }

  .OurMission {
    padding-top: 50px;
    h1 {
      font-size: 2em;
    }
  }
}

.Address {
  margin: auto;
  max-width: 600px;
  padding: 1.5rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.125rem;
  li {
    padding-bottom: .4rem;
  }
}

.SupportedBy {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  max-width: 1200px;
  margin: auto;
  img {
    max-height: 90px;
    filter: grayscale(100%);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}

.Enquire {
  position: relative;
  margin-top: 2rem;
  padding: 2rem .5rem 4rem .5rem;
  form {
    max-width: 600px;
    width: 100%;
    margin: auto;
    padding: 0 1rem;
    box-sizing: border-box;
  }
  h1 {
    font-size: 2rem;
    font-weight: 500;
    padding-bottom: 2rem;
    text-align: left;
    color: #fff;
  }

  /* BOC: checkbox style */
  .form-control {
    font-family: system-ui, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1;
    display: grid;
    grid-template-columns: 1em auto;
    gap: .8rem;
    padding-bottom: 1rem;
  }
  
  .form-control + .form-control {
    margin-top: 1em;
  }
  
  .form-control--disabled {
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
  
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--form-background);
    /* Not removed via appearance */
    margin: 0;
    
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
    cursor: pointer;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
    /* Windows High Contrast Mode */
    background-color: #fff;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  
  input[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  }
  
  input[type="checkbox"]:disabled {
    --form-control-color: var(--form-control-disabled);
  
    color: var(--form-control-disabled);
    cursor: not-allowed;
  }
  /* EOC: checkbox style */

  input[type=text], input[type=email] {
    background: rgba(255, 255, 255, 0.9);
    font-family: Quicksand;
    font-size: 1.125rem;
    font-weight: 600;
    color: $black;
    padding: 1rem 1rem;
    outline: none;
    margin-bottom: 1rem;
    width: 100%;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid transparent;
    &::placeholder {
      color:rgba(0, 0, 0, 0.6);
      font-weight: 400;
    }
    &:focus {
      border: 2px solid darken($onepurple, 10%);
      background-color: #fff;
    }
  }
  .Controls {
    display: flex;
    justify-content: flex-end;
  }
  button {
    padding: .6rem 1rem;
    font-size: 1.063rem;
    background: #FF9900;
    border-radius: 8px;
    border: none;
    font-weight: 800;
    color: $black;
    cursor: pointer;
    &:hover {
      background: lighten(#FF9900, 10%);
    }
    
  }
  em {
    background-color: #fff;
    padding: .5rem;
    border-radius: 5px;
    font-size: 1.125rem;
    margin-bottom: 1rem;
    display: none;
    color: rgba(100, 0, 0, 0.8);
    font-weight: 600;
    a {
      color: rgba(100, 0, 0, 0.8);
      font-weight: 800;
    }
    &.shown {
      display: block;
    }
  }
}

#contact {
  display: flex;
  flex-direction: column;
}

.Footer {
  text-align: center;
  padding: 0  1.5rem 2.5rem 1.5rem;
  line-height: 1.7rem;
  a {
    color: #fff;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
}

.Circle1, .Circle2, .Circle3 {
  border-radius: 200px;
  width: 200px;
  height: 200px;
  background: grey;
  position: absolute;
}

.Circle1 {
  top: 50px;
  right: 20px;
}

.Circle2 {
  top: 0;
  right: 160px;
  background: #f0f0f0;
}

.Circle3 {
  top: 130px;
  right: 130px;
  background: #d0d0d0;
}

.PriceList {
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  padding: 20px;
  list-style: none;
  padding: 5px;
  margin: 0;
  display: flex;
  gap: 20px;
  li {
    display: flex;
    flex: 1 1;
    min-height: 300px;
    border-radius: 10px;
    background: #fff;
    height: 100%;
  }
}

.TextSide__links {
  text-align: left;
  ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 2rem 0;
    flex-wrap: wrap;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    img {
      width: 150px;
      filter: grayscale(100%);
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
    }
  }
}


.Content {
  @media only screen and (max-width: 1024px) {
    .SideImage {
      flex-direction: column;
      .ImageSide {
        text-align: center;
        img {
          width: 100%;
        }
      }
    }
  }
}

#successLooksLike {
  .ImageSide {
    img {
      margin-top: -4rem;
    }
    @media only screen and (max-width: 1024px) {
      img {
        margin-top: 0 !important;
      }
    }
  }
}

.ThankYouDialog {
  .ThankYouDialog__message {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: $onepurple;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 2rem;
    img {
      width: 200px;
      display: block;
      padding-bottom: 1rem;
    }
  }
}

.FeaturedIn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin: auto;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-height: 50px;
    filter: grayscale(100%);
    opacity: 0.7;
  }
}

.InsideBanner {
  // background: lighten($onepurple, 10);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.InsideBannerContent {
  display: flex;
  max-width: 1700px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  padding: 4rem 0;
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
}

.InsideBannerContent__details {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 2rem;
  flex-direction: column;
  gap: 1rem;
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
  h1 {
    font-weight: 800;
    font-size: 3rem;
    line-height: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
    color: #fff;
    @media only screen and (max-width: 1024px) {
      font-size: 1.6rem;
      line-height: .1rem;
    }
  }
  h2 {
    color: #fff;
    font-size: 23px;
    font-weight: 400;
    line-height: 2rem;
  }
  max-width: 700px;
}

.InsideBanner__img {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  img {
    margin-top: 1rem;
    height: 300px;
    @media only screen and (max-width: 1024px) {
      height: 200px;
      padding-bottom: 1rem;
      margin-left: -50px;
    }
  }
}

.PlayLanguage {
  position: relative;
  min-height: 650px;
  margin-bottom: 2rem;
  @media only screen and (max-width: 1024px) {
    min-height: auto;
  }
}

.PlayLanguage__image {
  width: 650px;
  height: 650px;
  margin-left: -275px;
  object-fit: cover;
  object-position: 20% top; 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 700px;
  border: 5px solid #d7d7d7;
  @media only screen and (max-width: 1024px) {
    display: none;
  }

  
}

.PlayLanguage__content {
  color: #424242;
  margin: 0;
  box-sizing: border-box;
  padding-left: 300px;
  position: relative;
  z-index: 1;
  font-size: 1.125rem;
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
  }
  h1 {
    font-size: 2rem;
    color: $secondary;
    margin-bottom: 2rem;
  }
  p {
    line-height: 1.4rem;
  }
  
  ul {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(100%/1, max(280px, 100%/4)), 1fr));
    grid-gap: 1rem;
    @media only screen and (max-width: 1440px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    li {
      display: flex;
      gap: 1rem;
      flex-direction: column;
      width: 280px;
      min-height: 150px;
      border: 5px solid $pink;
      padding: 1rem;
      border-radius: 1rem;
      background-color: #fff;
      box-sizing: border-box;
      &:nth-child(2) {
        border-color: $green;
      }
      &:nth-child(3) {
        border-color: $sky;
      }
      &:nth-child(3) {
        border-color: $yellow;
      }
      &:nth-child(4) {
        border-color: $red;
      }
      &:nth-child(5) {
        border-color: $teal;
      }
      &:nth-child(6) {
        border-color: $navy;
      }
    }
  }
}

#about {
  .Content {
    @media only screen and (max-width: 1024px) {
      padding-top: 0;
    }
  }
}
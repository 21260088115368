@import '../../colors.scss';

.DownloadControls, .DownloadControlsInHeader {
  display: none;
  justify-content: flex-end;
  flex: 1 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: .5rem;
  box-sizing: border-box;
  padding-left: 2rem;
  // position: absolute;
  // right: 0;
  // top: -25§px;
  button {
      border: none;
      cursor: default;
      padding: 0;
      margin: 0;
      display: flex;
      background: none;
      opacity: 0.3;
  }
  a {
      opacity: 0.9;
      &:hover {
          opacity: 1;
      }
  }
  
  img {
    width: 120px;
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    margin-top: 0;
    img {
      width: 95px;
    }
  }
}

.DownloadControls {
  display: flex;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}

.MainMenu {
    display: flex;
    position: relative;
    flex: 1 1;
    ul {
      flex: 1 0;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      padding: 0 0 0 3rem;
      // display: flex;
      display: none;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0.2rem;
      li {
        a {
          font-weight: 600;
          padding: 0.5rem;
          display: inline-block;
          color: $black;
          border-radius: 5px;
          white-space: nowrap;
          &:hover {
            color: darken($black, 20%);
            background-color: #e0e0e0;
          }
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      display: none;
      position: fixed;
      top: 0;
      width: 100%;
      max-width: 200px;
      text-align: center;
      background: #fff;
      margin-top: 57px;
      right: 10px;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
      border-radius: 10px;
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        li {
          width: 100%;
          box-sizing: border-box;
          padding: 0 .5rem;
          a {
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }

    a[href="/youth"], a[href="/ro/youth"] {
      display: none;
    }
}

.TopStrip {
    width: 95vw;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 1rem 1rem;
    box-sizing: border-box;
    color: #101010;
    display: flex;
    gap: .5rem;
}

.MenuButton {
    width: 30px;
    height: 30px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: 0;
    cursor: pointer;
    background: none;
    &:hover {
        background: #e0e0e0;
    }
    z-index: 1001;
    // @media only screen and (max-width: 1024px) {
    //     display: flex;
    // }
}
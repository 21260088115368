.XcChallengeSummary {
    max-width: 990px;
    min-height: 500px;
    background: #fff;
    border-radius: 10px;
    flex: 1;
    overflow: hidden;
    border-width: 4px;
    border-style: solid;
    border-color: #4D1B1A;
    box-sizing: border-box;
    padding-bottom: 20px;
}

.XcChallengeSummary__image {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // border-radius: 10px 10px 0 0;
    width: 100%;
    min-height: 100px;
    position: relative;
    overflow: hidden;
    img {
        position: relative;
    }
}

.XcChallengeSummary_description, .XcChallengeSummary_intro {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 10px 15px;
    box-sizing: border-box;
}

.XcChallengeSummary__stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    padding: 10px;
}

.XcChallengeSummaryStat {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.XcChallengeSummaryStat__icon {
    svg {
        width: 30px;
        height: 30px;
    }
}

.XcChallengeSummary_title {
    padding: 15px 15px 10px 15px;
    font-size: 1.2rem;
    font-weight: 600;
}

.XcChallengeSummary__skills {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .5rem;
    padding: 5px 15px 10px 15px;
}

.XcChallengeSummarySkill {
    padding: 5px;
    border-radius: 3px;
    font-weight: 600;
}
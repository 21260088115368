.PrivacyRo {
    div {
        padding: 1rem 5rem;
        max-width: 1000px;
        margin: auto;
    }
    padding-bottom: 2rem;
}

.PrivacyRo__head {
    display: flex;
    flex: row;
    align-items: center;
    h1 {
        font-size: 1.5rem;
        flex: 1 0;
    }
    img {
        max-height: 200px;
    }
}